.kudu {
  max-height: 750px;
}

@media only screen and (max-width: 768px) {
  .kudu {
    max-height: 500px;
  }
}

@media only screen and (max-width: 500px) {
  .kudu {
    max-height: 300px;
  }
}

.vine {
  max-width: 250px;
}

@media only screen and (max-width: 768px) {
  .vine {
    max-width: 175px;
  }
}

@media only screen and (max-width: 500px) {
  .vine {
    max-width: 100px;
  }
}

.home-background {
  background-color: rgb(24, 24, 24);
}

.home-header {
  font-family: "Kristi", cursive;
  font-size: 7rem;
}

.home-intro {
  font-family: "Poiret One", cursive;
  font-size: 1.5rem;
}

.tree {
  max-width: 300px;
}
