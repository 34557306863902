.about-background {
  background-color: rgb(24, 24, 24);
}

.jumbotron {
  background-image: url("../../images/about/banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
}

.about-header {
    font-family: "Kristi", cursive;
    font-size: 5rem;
}

.about-image {
    max-height: 500px;
}

.skills-list {
    list-style: none;
    padding-inline-start: 0px !important;
}

.skills-header {
    font-family: "Poiret One", cursive;
    font-size: 2.5rem;
}

.about-card {
    opacity: .75;
}

.resume-link {
    font-family: "Poiret One", cursive;
    font-size: 1.5rem;
    color: black;
}
.resume-link:hover {
    color: turquoise;
}